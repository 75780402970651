<template>

  <!-- Page content -->
  <div class="page-content">

    <!-- Main navbar -->
    <div class="navbar navbar-expand-md navbar-dark bg-indigo-800 fixed-top">
      <div class="navbar-brand">
        <a href="/" class="d-inline-block">
          <img src="static/global_assets/images/logo_light.png" alt="FMS">
        </a>
      </div>

      <div class="d-md-none">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
          <i class="icon-tree5"></i>
        </button>
        <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
          <i class="icon-paragraph-justify3"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar-mobile">
        <!--<ul class="navbar-nav">-->
          <!--<li class="nav-item">-->
            <!--<a href="#" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">-->
              <!--<i class="icon-paragraph-justify3"></i>-->
            <!--</a>-->
          <!--</li>-->
        <!--</ul>-->

        <ul class="navbar-nav ml-auto" v-if="loggedin">
          <li class="nav-item">
          <a href="#" class="navbar-nav-link" >
            {{welcomemsg}}
          </a>
          </li>


          <li class="nav-item dropdown" >
            <a href="#" class="navbar-nav-link">
              <i class="icon-bell2"></i>
              <span class="d-md-none ml-2">Notifications</span>
              <span class="badge badge-mark border-white ml-auto ml-md-0"></span>
            </a>
          </li>

          <li class="nav-item dropdown dropdown-user" >
            <a href="#" class="navbar-nav-link d-flex align-items-center dropdown-toggle"
               data-toggle="dropdown">
              <img src="static/global_assets/images/image.png" class="rounded-circle mr-2" height="34" alt="">
              <span>{{username}}</span>
            </a>

            <div class="dropdown-menu dropdown-menu-right">
              <!--<router-link class="dropdown-item" to="/messages"><i class="icon-comment-discussion"></i>-->
                <!--Messages <span class="badge badge-pill bg-blue ml-auto">1</span></router-link>-->
              <!--<div class="dropdown-divider"></div>-->
              <router-link class="dropdown-item" to="/login"><i class="icon-switch2"></i> Logout
              </router-link>
            </div>
          </li>

        </ul>
      </div>
    </div>
    <!-- /main navbar -->

    <!-- Main sidebar -->
    <div class="sidebar sidebar-light sidebar-main sidebar-fixed sidebar-expand-md">

      <!-- Sidebar mobile toggler -->
      <div class="sidebar-mobile-toggler text-center">
        <a href="#" class="sidebar-mobile-main-toggle">
          <i class="icon-arrow-left8"></i>
        </a>
        Navigation
        <a href="#" class="sidebar-mobile-expand">
          <i class="icon-screen-full"></i>
          <i class="icon-screen-normal"></i>
        </a>
      </div>
      <!-- /sidebar mobile toggler -->


      <!-- Sidebar content -->
      <div class="sidebar-content">


        <!-- Main navigation -->
        <div class="card card-sidebar-mobile">
          <ul class="nav nav-sidebar" data-nav-type="accordion">

            <!-- Main -->
            <li class="nav-item">
              <router-link to="/" class="nav-link">
                <i class="icon-home4"></i>
                <span>Dashboard</span>
              </router-link>
            </li>
            <!-- /main -->


            <!-- Office -->
            <li class="nav-item-header">
              <div class="text-uppercase font-size-xs line-height-xs">Accounts</div>
              <i class="icon-menu" title="Accounts"></i>
            </li>
            <li class="nav-item nav-item-submenu">
              <a href="#" class="nav-link"><i class="icon-puzzle2"></i> <span>Accounts</span></a>

              <ul class="nav nav-group-sub" data-submenu-title="Accounts">

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Contra</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/newcontra">New Contra</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/opencontra">Open Contra List</router-link></li>
                  </ul>
                </li>


                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Receipt</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/newreceipt">New Receipt</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/openreceipt">Open Receipt List</router-link></li>
                  </ul>
                </li>


                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Payment</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/newpayment">New Payment</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/openpayment">Open Payment List</router-link></li>
                  </ul>
                </li>


                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Journal</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/newjournal">New Journal</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/openjournal">Open Journal List</router-link></li>
                  </ul>
                </li>



<!--                <li class="nav-item-divider"></li>-->

<!--                <li class="nav-item nav-item-submenu">-->
<!--                  <a href="#" class="nav-link">Credit Note</a>-->
<!--                  <ul class="nav nav-group-sub">-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/newcreditnote">New Credit Note</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/opencreditnote">Open Credit Note List</router-link></li>-->
<!--                  </ul>-->
<!--                </li>-->
<!--                -->
<!--                <li class="nav-item nav-item-submenu">-->
<!--                  <a href="#" class="nav-link">Debit Note</a>-->
<!--                  <ul class="nav nav-group-sub">-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/newdebitnote">New Debit Note</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/opendebitnote">Open Debit Note List</router-link></li>-->
<!--                  </ul>-->
<!--                </li>-->

              </ul>
            </li>
            <!--/Office -->

            <!-- Purchase -->
            <li class="nav-item nav-item-submenu">
              <a href="#" class="nav-link"><i class="icon-cart-add2"></i> <span>Purchase</span></a>

              <ul class="nav nav-group-sub" data-submenu-title="Purchase">

                <li class="nav-item"><router-link class="nav-link" to="/newinvoice">New Invoice</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/openinvoice">Open Invoice List</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/openinvoicepayment">Open Payment List</router-link></li>


              </ul>
            </li>
            <!--/Purchase -->

            <!--Sale-->
            <li class="nav-item nav-item-submenu">
              <a href="#" class="nav-link"><i class="icon-calculator"></i> <span>Sale</span></a>

              <ul class="nav nav-group-sub" data-submenu-title="Sale">
                <li class="nav-item"><router-link class="nav-link" to="/newsaleinvoice">New Sale Invoice</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/opensaleinvoice">Open Sale Invoice List</router-link></li>
<!--                <li class="nav-item-divider"></li>-->
<!--                <li class="nav-item"><router-link class="nav-link" to="/opensalereceipt">Open Sale Receipt</router-link></li>-->
                <li class="nav-item-divider"></li>
                <li class="nav-item"><router-link class="nav-link" to="/newsaleorder">New Sale Order</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/opensaleorder">Open Sale Order List</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/peningsaleorders">Pening Orders</router-link></li>


                <li class="nav-item-divider"></li>
                <li class="nav-item"><router-link class="nav-link" to="/newbale">New Dispatch</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/openbale">Open Dispatch List</router-link></li>

              </ul>
            </li>
            <!--/Sale-->

            <!-- Production -->
            <li class="nav-item nav-item-submenu">
              <a href="#" class="nav-link"><i class="icon-hammer-wrench"></i> <span>Production</span></a>

              <ul class="nav nav-group-sub" data-submenu-title="Production">

                <!--FMS-->

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Extrusion</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/new_extrusion_delivery">Delivery Entry</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_extrusion_delivery">Open Delivery List</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/new_extrusion_receipt">Receipt Entry</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_extrusion_receipt">Open Receipt List</router-link></li>
                  </ul>
                </li>

                <li class="nav-item-divider"></li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Warping</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/new_warp_delivery">Delivery Entry</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_warp_delivery">Open Delivery List</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/new_warp_receipt">Receipt Entry</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_warp_receipt">Open Receipt List</router-link></li>
                  </ul>
                </li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Knitting</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/new_knitting_delivery">Knitting Delivery</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_knitting_delivery">Open Knitting Delivery List</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/new_knitting_receipt">Knitting Receipt</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_knitting_receipt">Open Knitting Receipt List</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/new_container_reset">Container Reset</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_container_reset">Open Container Reset List</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/new_knitting_qc">Knitting QC</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_knitting_qc">Open Knitting QC List</router-link></li>

                  </ul>
                </li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Stentering</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/new_stentering">Stentering Entry</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_stentering">Open Stentering List</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/new_stenteringqc">Stentering QC Entry</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_stenteringqc">Open Stentering QC List</router-link></li>
                  </ul>
                </li>

                <li class="nav-item-divider"></li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Rolling</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/new_rolling">New Roll</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_rolling">Open Rolls List</router-link></li>
<!--                    <li class="nav-item-divider"></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/new_rollmerge">New Roll Merger</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/open_rollmerge">Open Roll Merger List</router-link></li>-->

                  </ul>


                </li>


                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Packing</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/newbale">New Bale</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/openbale">Open Bale List</router-link></li>
                  </ul>
                </li>

                <li class="nav-item-divider"></li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Stitching</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/newstitching">New Stitching </router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/openstitching">Open Stitching List</router-link></li>
                  </ul>
                </li>

                <li class="nav-item-divider"></li>
                <!--/FMS-->

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Jobwork</a>
                  <ul class="nav nav-group-sub">

                    <li class="nav-item"><router-link class="nav-link" to="/new_jobwork_delivery">New Delivery</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_jobwork_delivery">Open Delivery List</router-link></li>
                    <li class="nav-item-divider"></li>
<!--                    <li class="nav-item"><router-link class="nav-link" to="/new_jobwork_receipt">New Receipt</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/open_jobwork_receipt">Open Receipt List</router-link></li>-->

                    <li class="nav-item"><router-link class="nav-link" to="/new_jobwork_lotreceipt">New Lot</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_jobwork_lotreceipt">Open Lot List</router-link></li>
                    <li class="nav-item-divider"></li>

                    <li class="nav-item"><router-link class="nav-link" to="/new_jobwork_receipt">New Receipt</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_jobwork_receipt">Open Receipt List</router-link></li>
                    <li class="nav-item-divider"></li>

                    <li class="nav-item"><router-link class="nav-link" to="/new_rawmaterial_receipt">New RawMaterial Return</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_rawmaterial_receipt">Open RawMaterial Return List</router-link></li>
                    <li class="nav-item-divider"></li>


                    <li class="nav-item"><router-link class="nav-link" to="/new_jobwork_workorder">New Work Order</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/open_jobwork_workorder">Open Work Order List</router-link></li>

                  </ul>
                </li>

                <!--Reconciliation-->
                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Reconciliation</a>
                  <ul class="nav nav-group-sub">

                    <li class="nav-item"><router-link class="nav-link" to="/recon_raw_material">Raw Material</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/recon_yarn">Yarn</router-link></li>
<!--                    <li class="nav-item"><router-link class="nav-link" to="/recon_knitfabric">Knitted Fabric</router-link></li>-->
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/recon_knitraw">Knitted Raw</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/recon_knitraw_qc">Knitted QC</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/recon_stentering">Stentering</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/recon_stentering_qc">Stentering QC</router-link></li>
                  </ul>
                </li>
                <!--/Reconciliation-->

              </ul>
            </li>
            <!--/Production -->


            <!-- reports -->
            <li class="nav-item-header">
            <div class="text-uppercase font-size-xs line-height-xs">Reports</div>
            <i class="icon-menu" title="Reports"></i></li>
            <li class="nav-item nav-item-submenu">
              <a href="#" class="nav-link"><i class="icon-stack"></i> <span>Reports</span></a>

              <ul class="nav nav-group-sub" data-submenu-title="Reports">

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Stock</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/rawmaterialstock">Raw Material Stock</router-link></li>

                    <li class="nav-item"><router-link class="nav-link" to="/yarn_stock_report">Yarn Stock</router-link></li>
                    <li class="nav-item-divider"></li>
<!--                    <li class="nav-item"><router-link class="nav-link" to="/knitfabricstock">Knitted Fabric Stock</router-link></li>-->
                    <li class="nav-item"><router-link class="nav-link" to="/knit_rawfabricstock">Knitted Raw Fabric Stock</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/knit_raw_qc_fabricstock">Knitted QC Stock</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/knit_stentering_stock">Stentering Stock</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/knit_stentering_qc_stock">Stentering QC Stock</router-link></li>
                    <li class="nav-item-divider"></li>

                    <li class="nav-item"><router-link class="nav-link" to="/stitched_nets_stock">Stitched Nets</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/rollstock">Roll Stock</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/balestock">Bale Stock</router-link></li>
                  </ul>
                </li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Production</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/yarn_production">Yarn</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/rep_warping">Warping Report</router-link></li>

                    <li class="nav-item"><router-link class="nav-link" to="/rep_extrusion">Extrusion Report</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/rep_extrusionday">Extrusion Day Report</router-link></li>


                    <li class="nav-item"><router-link class="nav-link" to="/knitfabric_production">Knitted Fabric</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/stitched_nets_production">Stitched Nets</router-link></li>
                  </ul>
                </li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">QC Registers</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/knitting_qc_report">Knitting QC</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/stentering_qc_report">Stentering QC</router-link></li>
                  </ul>
                </li>

<!--                <li class="nav-item"><router-link class="nav-link" to="/fabric_stock_report">Fabric Stock</router-link></li>-->
<!--                <li class="nav-item"><router-link class="nav-link" to="/rep_containerstock">Container Stock</router-link></li>-->

<!--                <li class="nav-item nav-item-submenu">-->
<!--                  <a href="#" class="nav-link">Extrusion</a>-->
<!--                  <ul class="nav nav-group-sub">-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/rep_extrusion">Extrusion Report</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/rep_extrusionday">Day Report</router-link></li>-->
<!--                  </ul>-->
<!--                </li>-->

<!--                <li class="nav-item"><router-link class="nav-link" to="/rep_warping">Warping Report</router-link></li>-->
<!--                <li class="nav-item"><router-link class="nav-link" to="/rep_knitting">Knitting Report</router-link></li>-->

<!--                <li class="nav-item nav-item-submenu">-->
<!--                  <a href="#" class="nav-link">Warping</a>-->
<!--                  <ul class="nav nav-group-sub">-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/rep_warping">Warping Report</router-link></li>-->
<!--                  </ul>-->
<!--                </li>-->

<!--                <li class="nav-item nav-item-submenu">-->
<!--                  <a href="#" class="nav-link">Knitting</a>-->
<!--                  <ul class="nav nav-group-sub">-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/rep_knitting">Knitting Report</router-link></li>-->
<!--                  </ul>-->
<!--                </li>-->

                <li class="nav-item-divider"></li>

<!--                <li class="nav-item nav-item-submenu">-->
<!--                  <a href="#" class="nav-link">Accounts</a>-->
<!--                  <ul class="nav nav-group-sub">-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/daybook_display">Day Book</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/ledger_display">Ledger</router-link></li>-->
<!--                    <li class="nav-item-divider"></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/group_summary_display">Group Summary</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/tds_summary_display">TDS Summary</router-link></li>-->
<!--                    <li class="nav-item-divider"></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/trailbalance">Trial Balance</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/pandl">Profit & Loss</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/balancesheet">Balance Sheet</router-link></li>-->
<!--                  </ul>-->
<!--                </li>-->

<!--                <li class="nav-item nav-item-submenu">-->
<!--                  <a href="#" class="nav-link">GST</a>-->
<!--                  <ul class="nav nav-group-sub">-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/gst3b">3B</router-link></li>-->
<!--                    <li class="nav-item"><router-link class="nav-link" to="/gst3bdetailed">3B Detailed</router-link></li>-->
<!--                  </ul>-->
<!--                </li>-->


<!--                <li class="nav-item">-->
<!--                  <router-link class="nav-link" to="/status">Current Status</router-link>-->
<!--                </li>-->
                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Work Order</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/workorder_status">Work Order Status</router-link></li>
                  </ul>
                </li>
                <li class="nav-item-divider"></li>


<!--                <li class="nav-item">-->
<!--                  <router-link class="nav-link" to="/formdesigner">Form Designer</router-link>-->
<!--                </li>-->

<!--                <li class="nav-item">-->
<!--                  <router-link class="nav-link" to="/reportdesigner">Report Designer</router-link>-->
<!--                </li>-->

              </ul>
            </li>
            <!--/reports -->


            <!-- Master -->
            <li class="nav-item-header">
              <div class="text-uppercase font-size-xs line-height-xs">Master</div>
              <i class="icon-menu" title="Master"></i>
            </li>
            <li class="nav-item nav-item-submenu">
              <a href="#" class="nav-link"><i class="icon-gear"></i> <span>Master</span></a>

              <ul class="nav nav-group-sub" data-submenu-title="Master">

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Accounting</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/ledgergroup">Ledger Group</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/ledger">Ledger</router-link></li>
                  </ul>
                </li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Clients</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/agent">Agent</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/supplier">Supplier</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/buyer">Buyers</router-link></li>
                    <!--<li class="nav-item-divider"></li>-->
                    <li class="nav-item"><router-link class="nav-link" to="/serviceprovider">Service Providers</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/jobworker">Jobworkers</router-link></li>
                  </ul>
                </li>


                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Items</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/itemgroup">Group</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/item">Item</router-link></li>
                  </ul>
                </li>

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">Human Resource</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item"><router-link class="nav-link" to="/hr-emp">Employee</router-link></li>
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/hr-designation">Designation</router-link></li>
                  </ul>
                </li>
                <!--<li class="nav-item"><router-link class="nav-link" to="/gen-settings">Settings</router-link></li>-->
                <!--<li class="nav-item"><router-link class="nav-link" to="/form-designer">Form Designer</router-link></li>-->

                <!--FMS-->

                <li class="nav-item nav-item-submenu">
                  <a href="#" class="nav-link">F M S</a>
                  <ul class="nav nav-group-sub">
                    <li class="nav-item nav-item-submenu">
                      <a href="#" class="nav-link">Raw Materials</a>
                      <ul class="nav nav-group-sub">
                        <li class="nav-item"><router-link class="nav-link" to="/fms_hdpe">HDPE</router-link></li>
                        <li class="nav-item-divider"></li>
                        <!--<li class="nav-item"><router-link class="nav-link" to="/itemcategory">Cateogry</router-link></li>-->
                        <li class="nav-item"><router-link class="nav-link" to="/itembrand">Brand</router-link></li>
                        <li class="nav-item-divider"></li>
                        <li class="nav-item"><router-link class="nav-link" to="/fms_color">Color</router-link></li>
                        <li class="nav-item"><router-link class="nav-link" to="/fms_pigment">Pigment</router-link></li>
                        <li class="nav-item-divider"></li>
                        <li class="nav-item"><router-link class="nav-link" to="/fms_uv">UV</router-link></li>
                        <li class="nav-item"><router-link class="nav-link" to="/fms_zincpowder">Zinc Powder</router-link></li>
                        <li class="nav-item"><router-link class="nav-link" to="/fms_masterbatch">Master Batch</router-link></li>
                      </ul>
                    </li>
                    <li class="nav-item"><router-link class="nav-link" to="/fms_fabric">Fabric</router-link></li>

                    <li class="nav-item"><router-link class="nav-link" to="/fms_quality">Quality</router-link></li>
                    <!--<li class="nav-item-divider"></li>-->
                    <!--<li class="nav-item"><router-link class="nav-link" to="/fms_cone">Cone</router-link></li>-->
                    <!--<li class="nav-item"><router-link class="nav-link" to="/fms_beam">Beam</router-link></li>-->
                    <li class="nav-item-divider"></li>
                    <li class="nav-item"><router-link class="nav-link" to="/machine">Machine</router-link></li>
<!--                    <li class="nav-item"><router-link class="nav-link" to="/defect">Defect</router-link></li>-->
                    <li class="nav-item"><router-link class="nav-link" to="/container">Container</router-link></li>

                  </ul>
                </li>
                <!--/FMS-->

                <li class="nav-item"><router-link class="nav-link" to="/companies">Companies</router-link></li>


              </ul>
            </li>
            <!--/Master -->

            <!-- logout -->
            <li class="nav-item-header">
              <div class="text-uppercase font-size-xs line-height-xs">Logout</div>
              <i class="icon-menu" title="Logout"></i>
            </li>

            <router-link class="dropdown-item" to="/login"><i class="icon-switch2"></i> Logout </router-link>
            <!--/logout -->

          </ul>
        </div>
        <!-- /main navigation -->

      </div>
      <!-- /sidebar content -->

    </div>
    <!-- /main sidebar -->


    <!-- Main content -->
    <div class="content-wrapper">

      <!-- Page header -->
      <div v-if="pageheader_visible" class="page-header page-header-light">


        <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
          <div class="d-flex">
            <div class="breadcrumb">
              <a href="index.html" class="breadcrumb-item"><i class="icon-home2 mr-2"></i> Home</a>
              <a href="#" class="breadcrumb-item">Link</a>
              <span class="breadcrumb-item active">Current</span>
            </div>

            <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
          </div>

          <div class="header-elements d-none">
            <div class="breadcrumb justify-content-center">
              <a href="#" class="breadcrumb-elements-item">
                Link
              </a>

              <div class="breadcrumb-elements-item dropdown p-0">
                <a href="#" class="breadcrumb-elements-item dropdown-toggle" data-toggle="dropdown">
                  Dropdown
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                  <a href="#" class="dropdown-item">Action</a>
                  <a href="#" class="dropdown-item">Another action</a>
                  <a href="#" class="dropdown-item">One more action</a>
                  <div class="dropdown-divider"></div>
                  <a href="#" class="dropdown-item">Separate action</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /page header -->


      <!-- Content area -->
      <div id="mycontent" class="content" style="padding: 5px;">

            <router-view/>

      </div>
      <!-- /content area -->


      <!-- Footer -->
      <!--<div class="navbar navbar-expand-lg navbar-light fixed-bottom">-->
      <div  id="appfooter" class="navbar navbar-expand-lg navbar-light " tabindex="-1">
        <div class="text-center d-lg-none w-100">
          <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
            <i class="icon-unfold mr-2"></i>
            Footer
          </button>
        </div>

        <div class="navbar-collapse collapse" id="navbar-footer" tabindex="-1">
            <span class="navbar-text">
                &copy; 2024 . <a href="#" tabindex="-1">Limitless </a> by <a href="#"  tabindex="-1" target="_blank">Py Labs</a>
            </span>
        </div>
      </div>
      <!-- /footer -->

      <!-- Validation form -->
      <div id="modal-validation" class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">

            <!-- Form -->
            <form class="modal-body form-validate" action="#">
              <div class="text-center mb-3">
                <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                <h5 class="mb-0">Choose your Branch</h5>
                <span class="d-block text-muted">& Financial Year</span>
              </div>

              <div class="form-group form-group-feedback form-group-feedback-left">
                <select id="cmbbranch" class="form-control" required autofocus v-model="branch.code">
                  <option v-for="brn in branches" v-bind:value="brn.code">
                    {{ brn.name }}
                  </option>
                </select>
                <div class="form-control-feedback">
                  <i class="icon-box text-muted"></i>
                </div>
              </div>

              <div class="form-group form-group-feedback form-group-feedback-left">
                <select class="form-control" required v-model="financialyear">
                  <option value="2019">2019</option>
                </select>
                <div class="form-control-feedback">
                  <i class="icon-calendar2 text-muted"></i>
                </div>
              </div>

              <div class="form-group d-flex align-items-center">
                <div class="custom-control custom-checkbox mb-3">
                  <input type="checkbox" class="custom-control-input" id="cbremember" >
                  <label class="custom-control-label" for="cbremember">Remember</label>
                </div>

              </div>


              <div class="form-group">
                <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block" >Let's Go <i class="icon-circle-right2 ml-2"></i></button>
              </div>

            </form>
            <!-- /form -->

          </div>
        </div>
      </div>
      <!-- /validation form -->


    </div>
    <!-- /main content -->

  </div>
  <!-- /page content -->

</template>

<script>
// import FabButton from '@/components/core/FabButton.vue'
// import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import * as focusTrap from 'focus-trap';

export default {
  components: {
    // 'FocusTrap': FocusTrap,
  },
  store,
  data() {
    return {
      isActive: false,
      branches:[],
      branch: {"id":"","code":1,"type":0,"name":"","street":"","city":"","pincode":"","state_cd":33,"contact_person":"","telephone":"","email":""},
      financialyear: 0,
      pageheader_visible: false,
      welcomemsg: 'Welcome, Admin!',
      loggedin: false,
      username: ''
    }
  },
  watch: {
    'store.state': function (newVal) {
    }
  },
  created() {
    // $('#applist').hide();
    // this.$data.loggedin = userService.isloggedIn();

  },
  mounted() {
    const self = this;

    // process.env.VUE_APP_ROOT_API = "http://localhost:8000/"
    // alert(process.env.VUE_APP_ROOT_API);

    // document.addEventListener('storage', this.logStorageChange);
    $(window).on('storage', function (e) {
      // console.log(e.originalEvent.key, e.originalEvent.newValue);
    });


    if (userService.isloggedIn()) {
      this.$data.loggedin= true;
      let profile =  JSON.parse(localStorage.getItem('fmsuser'));

      // let user = $cookies.get('tmsuser');
      store.state.user = profile;

      this.$data.welcomemsg = store.state.user.company.name + ' @ ' + store.state.user.finyear;

      this.$data.username = profile.name;


    }else{
      this.$data.loggedin= false;
      this.$router.push({ path: '/login' });
    }

  },
  methods: {

  }
}
</script>
